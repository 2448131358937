<template>
	<div class="admin-container">
		<Head />
		<div class="admin-main admin-col-bg flex">
			<div class="admin-aside">
				<UserNavA></UserNavA>
			</div>
			<div class="admin-col  whole_000">
				<div class="coll-post whole_fff">
					<router-link to="/collection">{{$t('meun13_2')}}</router-link> > <span>{{$t('clTxt2')}}</span>
				</div>
				<div class="coll3_box whole_fff">
					<div class="coll3-box">
						<div class="coll3-tt">Bank Transfer</div>
						<el-form :model="ruleForm" ref="ruleForm" class="coll3-form">
							<el-form-item prop="type">
								<div class="coll3-group coll3_sel">
									<div class="coll3_tt">{{$t('meun13_2')}}</div>
									<el-select v-model="ruleForm.type" :placeholder="$t('placeholder3')"
										@change="selectChange">
										<el-option v-for="(item,index) in payList" :key="index" :label="item.code"
											:value="item.id">
										</el-option>
									</el-select>
								</div>
							</el-form-item>
							<div v-for="(item,index) in formLabel" :key="index">
								<el-form-item :prop="item.fieldName"
									:rules="[{required: item.require, message: item.placeholder, trigger: 'blur' }]">
									<div class="coll3-group" v-if="item.type == 'input'">
										<div class="coll3_tt">{{item.showText}}</div>
										<el-input v-model="ruleForm[item.fieldName]" :placeholder="item.placeholder">
										</el-input>
									</div>
									<div class="coll3-group" v-if="item.type == 'image'">
										<div class="coll3_tt">{{item.showText}}</div>
										<el-upload class="coll3_uplod" :action="uploadUrl" :headers="uploadHeaders"
											:before-upload="beforeUpload" :show-file-list="false"
											:on-success="handelSuccess">
											<img v-if="ruleForm.imageFieldName" :src="ruleForm.imageFieldName"
												class="avatar">
											<div v-else class="el-upload-btn">
												<i class="iconfont icon-upload"></i>
												<span>{{$t('upload')}}</span>
											</div>
										</el-upload>
										<div class="coll4_up_ti">{{$t('uploadTip')}}</div>
									</div>
								</el-form-item>
								<div class="coll3_cozy" v-if="item.type== 'tip'">
									<div class="coll3_cozy_tt">{{item.showText}}</div>
									<p class="line2">{{item.placeholder}}</p>
								</div>
								<!-- <div class="coll3-ttt flex">
									<i class="el-icon-warning"></i>
									<div class="coll3_bd">
										{{item.showText}}
										<p class="line-2">{{item.placeholder}}</p>
									</div>
								</div> -->
							</div>
							<el-form-item>
								<div class="coll3_btn  flex flex-ac flex-between">
									<el-button class="btn btn_reset"  type="primary" @click="resetForm('ruleForm')">
										{{$t('reset')}}
									</el-button>
									<el-button class="btn"  type="primary" @click="submitForm('ruleForm')">{{$t('save')}}
									</el-button>
								</div>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		findPayment,
		paymentList,
		savePayment
	} from '@/api/api/user'
	// @ is an alias to /src
	import UserNavA from '@/components/UserNavA.vue'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			UserNavA,
			Foot
		},
		data() {
			return {
				payList: [],
				ruleForm: {
					field_1:'',
					field_2:'',
					field_3:'',
					field_4:'',
					field_5:'',
					field_6:'',
					field_7:'',
					imageFieldName: ''
				},
				formLabel: [],
				uploadUrl: "https://api.al-cex.com/uc/upload/oss/image",
				uploadHeaders: {
					"x-auth-token": localStorage.getItem("token")
				},
			};
		},
		created() {

		},
		computed: {

		},
		mounted() {
			this.getInfo()
			this.getList()
		},
		methods: {
			beforeUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 1;

				if (!isLt2M) {
					this.$message.error(this.$t('uploaderr') + '1MB');
				}
				return isLt2M;
			},
			handelSuccess(res, file) {
				if (res.code == 0) {
					this.ruleForm.imageFieldName = file.response.data
				} else {
					this.$message.error(res.message);
				}

			},
			getList() {
				paymentList().then(res => {
					if (res.code == 0) {
						this.payList = res.data
					}
				})
			},
			getInfo() {
				findPayment({
					id: this.ruleForm.type
				}).then(res => {
					console.log(res)
					if (res.code == 0) {
						this.formLabel = res.data
					}
				})
			},
			resetForm() {
				this.ruleForm.field_1 = '';
				this.ruleForm.field_2 = '';
				this.ruleForm.field_3 = '';
				this.ruleForm.field_4 = '';
				this.ruleForm.field_5 = '';
				this.ruleForm.field_6 = '';
				this.ruleForm.field_7 = '';
			},
			submitForm(formName) {
				console.log(this.ruleForm)
				this.$refs[formName].validate((valid) => {
					if (valid) {
						savePayment(this.ruleForm).then(res => {
							if (res.code == 0) {
								this.$message({
									message: this.$t('save_success'),
									type: 'success'
								});
								this.$router.push('/collection')
							} else {
								this.$message.error(this.$t('save_failure'));
							}
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			selectChange(value) {
				this.resetForm()
				this.ruleForm.type = value
				this.getInfo()
			}
		}
	}
</script>
